@import "variables";


.frame-type-gridelements {
    .teaser-grid,
    .teaser-container {
        margin: -($grid-gutter-width / 2);
    }
}

@include media-breakpoint-down(sm) {
    .grid-type-wvier_grid_teasergrid > .container,
    .grid-type-wvier_grid_container > .container {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
}

.frame-type-wvier_teaser {
    position: relative;
    background-color: $light;
    margin: 0;
    padding: 0;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .teaser-inner {
        position: absolute;
        left: $grid-gutter-width/2;
        right: $grid-gutter-width/2;
        top: $grid-gutter-width/2;
        bottom: $grid-gutter-width/2;
        overflow: hidden;

        > a {
            display: inline-block;

            &:hover {
                .btn-secondary {
                    color: $white;
                }

                .img-teaser {
                    transform: scale(1.025,1.025);
                }
            }
        }
    }

    .img-teaser {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-out 0s;
    }

    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.teaser-container {
    display: flex;
    flex-wrap: wrap;

    .frame-type-wvier_teaser {
        width: 50%;
        @include media-breakpoint-up(md) {
            width: 33.33333%;
        }
    }
}

@media (max-width: 359px) {
    .teaser-container {
        .frame-type-wvier_teaser {
            width: 100%;
        }
    }
}

.teaser-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;

    @include media-breakpoint-up(md) {
        grid-template-columns: 33.33333% 33.33333% 33.33333%;

        .teaser-gridcol-1 {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        .teaser-gridcol-2 {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
        }

        .teaser-gridcol-3 {
            grid-column: 3 / span 1;
            grid-row: 1 / span 2;
        }

        .teaser-gridcol-4 {
            grid-column: 1 / span 2;
            grid-row: 2 / span 1;
        }

        .teaser-gridcol-3 {
            .frame-type-wvier_teaser {
                &::before {
                    padding-top: 200%;
                }
            }
        }

        .teaser-gridcol-4 {
            .frame-type-wvier_teaser {
                &::before {
                    padding-top: 50%;
                }
            }
        }
    }

    .frame-type-wvier_teaser {
        width: 100%;
    }
}

@media (max-width: 374px) {
    .frame-type-wvier_teaser {
        .btn {
            font-size: 1rem;
            padding: 0.375rem;
        }
    }
}


